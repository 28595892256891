* {
  font-family: "Noto Sans KR", sans-serif !important; /* 20201203 박태성C */
}

body {
  margin: 0;
  padding: 0;
  background-color: #d9d9d9 !important; /* 11.26 김주호 수정 PLATFORM-468 */

  /* 11.30 김주호 : 사용자 텍스트 선택 차단 */
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

/* 박태성 */
body.logout {
  overflow-x: hidden;
}

/****** 공통 부분 ******/

#main {
  min-width: 1006px;
  overflow: hidden;
}

.hide {
  display: none !important;
}
/* 결제 iframe */
#payment_iframe {
  display: block;
  border: none;
  height: 100%;
  width: 100%;
}

/* 페이지 레이아웃 */
/**
!!!!! 주의 :
 페이지 css를 따로 관리 애들이 있으니
 전체적으로 페이지 사이즈를 수정할 시, 따로 관리하는 페이지에도 따로 적용해 주세요.
**/

#page_container {
  max-width: 1400px;
  /*width : 1500px;*/
}

/*탭메뉴*/
.MuiTabs-flexContainer {
  border-bottom: 1px solid #ddd;
}
.MuiTab-root {
  font-size: 16px !important;
}
.MuiTab-textColorInherit.Mui-selected {
  font-weight: bold !important;
}
.MuiTabs-indicator {
  height: 4px !important;
}

/*좌측 네비게이션*/
.metismenu {
  background: #F6F7F9;
  font-family: "MalgunGothic" !important;
}
.metismenu::after {
  box-shadow: inherit !important;
}
.metismenu-link {
  /* -webkit-transition: none !important; */
  text-shadow: unset;
}
.metismenu-link.active {
  color : #ffffff;
  background: #174398;
  text-shadow: initial;
}
.metismenu-link.has-active-child {
  color: #575E68;
}
.metismenu-container,
.metismenu-item {
  /* border: 1px solid red; */
}
.metismenu-container.visible {
  padding: 0;
}
.metismenu-container.visible > .metismenu-item > .metismenu-link {
  height: 55px;
}
.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link
  .metismenu-state-icon {
  margin-top: 5px;
  position: absolute;
  top: 2px;
  right: 0;
}
.metismenu-container .metismenu-container {
  /* background: initial !important;
  -webkit-box-shadow: inherit !important;
  box-shadow: inherit !important; */
}
i.metismenu-icon {
  font-size: 36px;
  width: 72px;
  margin-top: 6px;
}

#open-false .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 0 !important;
}

#open-true
  .metismenu-container
  .metismenu-container
  .metismenu-link
  i.metismenu-icon {
  font-size: 30px !important;
  margin-top: 9px !important;
}

#open-false
  .metismenu-container
  .metismenu-container
  .metismenu-link
  i.metismenu-icon {
  font-size: 22px !important;
  margin-top: 18px !important;
}
/*좌측 네비게이션*/

/*데이타 테이블*/
.jEDPQU {
  display: block !important;
}
/*데이타 테이블*/

/* 테이블 */
/* 박태성C - 업소 관리 */
.MuiTableCell-root.paddingNone {
  padding: 0px !important;
}

.MuiTableCell-root {
  padding: 8px !important;
}

.MuiTablePagination-selectRoot {
  margin-right: 4px !important;
}

.MuiTablePagination-root {
  display: flex;
}

.MuiTablePagination-selectRoot {
  margin-right: 4px !important;
}

.MuiTablePagination-toolbar {
  margin: 0 auto !important;
  padding: 0 !important;
}

.MuiTablePagination-input {
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* 테이블 */

/* primary 버튼 */
.MuiButton-containedPrimary {
  /* border-radius: 20px !important; */
}

/* secondary 버튼*/
.MuiButton-containedSecondary {
  border: 1px solid #ccc;
}

.MuiContainer-root {
  /* padding: 0 !important; */
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #f93273 !important;
}

.custom-modal-button {
  background: #4B93FF !important;
  padding: 6px 24px !important;
}

.custom-modal-button:disabled {
  background: #e0e0e0 !important;
}

.divider-height3 {
  width: 100%;
  height: 3px !important;
  background: #ccc !important;
}

.pos-relative {
  position: relative;
}

.display-flex {
  display: flex;
}

.display-inline-block {
  display: inline-block;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-badge {
  height: 20px;
  line-height: 20px;
  display: inline-flex;
  padding: 0 7px;
  font-size: 11px;
  min-width: 20px;
  align-items: center;
  font-weight: 600;
  border-radius: 20px;
  background-color: #f34788;
  color: #fff;
}

.necessary {
  color: #f93737;
  font-weight: bold;
}

/* 토클 버튼 선택시*/
.MuiToggleButton-root.Mui-selected {
  color: #fff !important;
  background-color: #4B93FF !important;
  font-weight: bold !important;
}

.MuiToggleButton-root.Day-red.Mui-selected {
  background: #ff4a4a !important;
  background-color: #ff4a4a !important;
}

.MuiToggleButton-root.Day-blue.Mui-selected {
  background: #008aff;
  background-color: #008aff !important;
}

/* .custom-top-badge .MuiBadge-badge {
  background: red;
  color: #fff;
} */

.MuiOutlinedInput-input {
  background: #fff !important;
}

#CommonFormList-PC {
  /* border: '1px solid red'; */
}

#CommonFormList-PC .MuiBox-root {
  display: flex;
  border-bottom: 1px solid #ddd;
}

#CommonFormList-PC .MuiBox-root:first-child {
  border-top: 1px solid #ddd;
}

#CommonFormList-PC .MuiBox-root:last-child {
  border-bottom: none;
}

#UpDownBox .MuiBox-root {
  display: flex;
}

#UpDownBox .MuiBox-root:first-child {
  border-top: none;
  border-bottom: none;
}

#UpDownBox .MuiBox-root:last-child {
  border-top: none;
  border-bottom: none;
}

#CommonFormList-PC .MuiOutlinedInput-root {
  min-height: 36px;
}

#CommonFormList-PC .MuiFormControl-marginNormal {
  margin: 0;
}

#CommonFormList-PC .title {
  width: 20%;
  min-height: 54px;
  line-height: 54px;
  margin: 0;
  margin-right: 5px;
  background: #f8f8f9;
  text-align: center;
  font-weight: bold;
  color: #676767;
}
#CommonFormList-PC .contentBody {
  width: 80%;
  line-height: 32px;
  padding: 8px;
}

#CommonFormList-PC .title2 {
  width: 20.1%;
  min-height: 54px;
  line-height: 54px;
  margin: 0;
  margin-right: 5px;
  background: #f8f8f9;
  text-align: center;
  font-weight: bold;
  color: #676767;
}
#CommonFormList-PC .contentBody2 {
  width: 29.9%;
  line-height: 32px;
  padding: 8px;
}

#CommonFormList-PC .contentBody .items {
  /* margin-top: 16px;
  margin-bottom: 8px; */
}

#CommonFormList-PC .contentBodyNoBorder .MuiBox-root {
  border: none;
  display: "inline-block";
}

#CommonFormInput-PC {
  background: #f3f3f3;
  padding: 8px 16px;
  border-radius: 5px;
}

#CommonFormInput-PC .MuiOutlinedInput-root {
  background: #fff;
}

.grade-box-sqaure {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 4px;
  margin-top: 2px;
  vertical-align: middle;
}

/* 데이트 피커 z-index*/
.react-datepicker-popper {
  z-index: 2 !important;
}

.datepicker-drst3in {
  border: 1px solid red;
}

/****** 공통 부분 ******/

/****** 로그인 pc  ******/
/* 20200603 박태성 - 해당 컴포넌트에서 관리하므로 CSS 삭제 */
/****** 로그인 pc  ******/

/****** 아이디 패스워드 찾기 ******/
#FindIdAndPassword-PC .MuiBox-root {
  display: flex;
  margin-top: 5rem;
}

#FindIdAndPassword-PC .wrapBox {
  margin-top: 0.8rem;
}

#FindIdAndPassword-PC .title {
  position: relative;
  width: 200px;
  margin-left: -200px;
  text-align: right;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
  padding-right: 1rem;
}

#FindIdAndPassword-PC .contentBody {
  width: 100%;
  line-height: 32px;
  padding: 0;
  padding-top: 0.063rem;
}

#FindIdAndPassword-PC .contentBody .formControlButton {
  margin-left: 0.625rem;
  width: calc(30% - 0.625rem);
  margin-top: 0.063rem;
}

#FindIdAndPassword-PC .contentBody .inputWithButton {
  width: 70%;
}
/****** 아이디 패스워드 찾기 ******/

/****** Helper Text (Material UI) ******/
/* 박태성 - Material에서 제공하는 FormHelperText 콤포넌트 스타일 */
/* 회원가입/제휴신청/마이페이지 등 여러 화면의 가이드 문구를 <p> 태그 기반으로 처리 */
.helperText {
  color: rgba(0, 0, 0, 0.54);
  font-size: 11.62px;
  font-weight: 700;
  line-height: 16.6166px;
  letter-spacing: 0.14994px;
  white-space: pre-line;
}

.helperText a {
  text-decoration: none;
}

.helperText a:hover {
  text-decoration: underline;
}

/* .helperText.error a:link, .helperText.error a:visited, .helperText.error a:active */

.helperText.error,
.helperText.error * {
  color: #f44336;
}

.helperText.warning,
.helperText.warning * {
  color: #f34788;
}

.helperText.info,
.helperText.info * {
  color: #008aff;
}
/****** Helper Text (Material UI) ******/

/****** 약관 동의 pc ******/
/* 20200603 박태성 - 해당 컴포넌트에서 관리하므로 CSS 삭제 */
/****** 약관 동의 pc ******/

/****** 회원 가입 pc ******/
#Register-PC {
  /* border: 1px solid red; */
}

#Register-PC .form {
  width: 700px;
}

#Register-PC .MuiBox-root {
  display: flex;
}

#Register-PC .title {
  /* border: 1px solid red; */
  width: 20%;
  height: 58px;
  line-height: 58px;
  margin: 16px;
}
#Register-PC .contentBody {
  width: 80%;
}
#Register-PC .contentBody2 {
  width: 57%;
}
#Register-PC .contentBody2 .textField {
  width: 32%;
  margin-right: 1%;
  /* border: 1px solid red; */
  /* display: inline-block; */
}
#Register-PC .contentBody2 .items {
  margin-top: 16px;
  margin-bottom: 8px;
}
#Register-PC .contentTail {
  width: 23%;
  height: 58px;
  line-height: 53px;
  margin: 16px 0;
  text-align: center;
}

#Register-PC .contentTail button {
  width: 95%;
}

#Register-PC .submit {
  width: 200px;
  margin: 30px 20px;
}
/****** 회원 가입 pc ******/

/****** 설정 pc ******/

#Settings-PC {
  max-width: 1400px;
}

#Settings-PC select {
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
}

#Settings-PC select:hover {
  border: "1px solid #000";
}

#Settings-PC select::-ms-expand {
  display: none;
}

#Settings-PC .button-box {
  /* border: 1px solid red; */
  display: inline;
  position: absolute;
  right: 16px;
  top: 10px;
}

#Settings-PC .display-inline-block {
  display: inline-block;
}

#Settings-PC .icons {
  position: absolute;
}
/****** 설정 pc ******/

/****** 마이페이지 PC ******/

#Mypage-PC {
  max-width: 1400px;
}

#Mypage-PC .tabroot {
  flex-grow: 1;
}

#BaseDateSelect select {
  /* styling */
  background-color: white;
  border: thin solid #cccccc;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  /* 네이티브 외형 감추기 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* IE 10, 11의 네이티브 화살표 숨기기 */
#BaseDateSelect select::-ms-expand {
  display: none;
}

#BaseDateSelect select:focus {
  outline: none;
  border: 1px solid #4B93FF;
}

#BaseDateSelect select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 0px 1.5em;
  background-repeat: no-repeat;
}

#SearchBox .MuiIconButton-root {
  padding: 6px;
}

#Floating a {
  text-decoration: none;
}

@media screen and (max-width: 1499px) {
  #Floating {
    left: 72px;
    width: calc(100vw - 72px);
  }
}
@media screen and (min-width: 1500px) {
  #Floating {
    left: 256px;
    width: calc(100vw - 256px);
  }
}

/* Calendar */
/****** 마이페이지 PC ******/
/****** 판매캘린더 ******/
.calendar .icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

/* GRID */

.calendar .row {
  margin: 0;
  /* padding: 0; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.calendar .row-middle {
  align-items: center;
}

.calendar .row-end {
  /* padding-top: 50px !important; */
  padding-top: 1em;
  padding-bottom: 1em;
}

/* .calendar .week-days {
  border-right: 1px solid var(--border-color);
  background: var(--bg-color);
} */

.calendar .col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.calendar .col-start {
  justify-content: flex-start;
  text-align: left;
}

.calendar .col-center {
  justify-content: center;
  text-align: center;
}

.calendar .col-end {
  justify-content: flex-end;
  text-align: right;
  padding-right: 0.5em;
  margin-right: 0.5em;
}

.calendar .col-between {
  margin-left: 0.5em;
}

.calendar .inlineBlock {
  display: inline-block;
}

.calendar {
  display: block;
  position: relative;
  width: 100%;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 0 0 1em 0;
}

.calendar .header .icon {
  cursor: pointer;
  border: 2px solid #cccccc;
  border-radius: 30px;
  padding: 0.1em;
  margin: 0 0.5em;
  background-color: #fff;
}

.calendar .header .icon:first-of-type {
  margin-left: 0.5em;
}

.calendar .header .icon:last-of-type {
  margin-right: 0.5em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 70%;
  padding: 0;
  border-left: 1px solid #cccccc;
}

.calendar .weekStyle {
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.calendar .body .row {
  border-left: 1px solid #cccccc;
}

.calendar .body .cell {
  height: auto;
  padding: 0.2em;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  overflow: hidden;
}

.calendar .body .cell .number {
  /* color: #ffffff; */
  color: #383838;
  /* position: absolute; */
  /* top: 0.5em; */
  /* left: 0.5em; */
  padding: 0.5em;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 700;
}

.calendar .dividerMarginTop {
  margin-top: 0.1em;
}

.calendar .body .col {
  /* flex-grow: 0;
  flex-basis: calc(100% / 7); */
  width: calc(100% / 7);
}

.calendar .MuiButton-root {
  min-width: 34px;
}

.calendar .tinyBtnDefault {
  display: inline-block;
  font-size: 0.7rem;
  width: 30%;
}

.calendar .tinyBtnDefault .MuiButton-root {
  color: #ffffff;
  background-color: #2196f3;
  padding: 0 0.5em;
  font-size: 0.7rem;
}

.calendar .tinyBtnDefault .Mui-disabled {
  color: #ffffff;
  background-color: #f44336;
  padding: 0 0.5em;
  font-size: 0.7rem;
}

.calendar .MuiButton-containedSizeSmall {
  padding: 1px;
}

.bg-room-open {
  /* 판매중 */
  color: #ffffff;
  font-weight: bold;
  border: solid 1px #36981f;
  background-color: #36981f !important;
}
/* PLATFORM-458대응  201130 임세결 수정 */
.bg-room-close {
  /* 마감 */
  color: #ff4a4a;
  font-weight: bold;
  border: solid 1px #ff4a4a;
  background-color: #ffffff !important;
}

/* PLATFORM-458대응  201130 임세결 추가 */
.bg-room-prepare {
  /* 준비중 */
  border: solid 1px #ff8c19;
  background-color: #ff8c19 !important;
}
.bg-room-soldout {
  /* 잔여 0 */
  border: solid 1px #a2a2a2;
  background-color: #a2a2a2 !important;
}

/*=======================================
쿠폰캘린더
========================================*/

.bg-coupon-open {
  /* 판매중 */
  text-align: center;
  width: 95%;
  color: #ffffff;
  font-weight: bold;
  border: solid 1px #36981f;
  background-color: #36981f !important;
}
.bg-coupon-close {
  /* 마감 */
  text-align: center;
  width: 95%;
  color: #ff4a4a;
  font-weight: bold;
  border: solid 1px #ff4a4a;
  background-color: #ffffff !important;
}

.bg-coupon-prepare {
  /* 준비중 */
  text-align: center;
  width: 95%;
  border: solid 1px #ff8c19;
  background-color: #ff8c19 !important;
}
.bg-coupon-soldout {
  /* 잔여 0 */
  text-align: center;
  width: 95%;
  border: solid 1px #a2a2a2;
  background-color: #a2a2a2 !important;
}
/*=======================================
쿠폰캘린더
========================================*/

.color-saleday {
  color: #ff4a4a !important;
}

.color-salehour {
  color: #098afb !important;
}

.color-thirdTheme {
  color: #000 !important;
}
.coverCalendar .MuiCardContent-root {
  padding: 0;
}

.coverCalendar .MuiCardContent-root:last-child {
  padding-bottom: 0;
}

#SettingSale .MuiTableHead-root {
  border-bottom: 2px solid #e5e5e5;
}

#SettingSale .MuiTableCell-root {
  border-right: 1px solid #e5e5e5;
}
#SettingSale .MuiTableCell-root:last-child {
  border-right: none;
}

/****** 판매캘린더 ******/

/****** 업소정보관리 PC ******/
#BusinessInfo-PC {
  max-width: 1400px;
}
#BusinessInfo-PC .tabroot {
  flex-grow: 1;
}

/****** 업소정보관리 PC ******/

/****** 기준요금 ******/
#StandardCharge-PC {
  max-width: 1400px;
}
/****** 기준요금 ******/

/****** 기준시간 ******/
#StandardTime-PC {
}
/****** 특정일 요금 ******/
#DayCharge-PC {
  max-width:1400px
}

/****** 이벤트 ******/
#Promotion-PC {
  max-width:1400px
}

/****** 통계 ******/
#Statistics-PC{
  max-width:1400px
}

/****** 공지사항 ******/
#Notice-PC{
  max-width: 1400px;
}

/****** FAQ ******/
#Faq-PC {
  max-width: 1400px;
}

/****** 1:1문의 ******/
#Question-PC{
  max-width: 1400px;
}

/****** 제휴신청 PC ******/
#Alliance-PC {
}

#Alliance-PC .tabroot {
  flex-grow: 1;
}

#Alliance-PC .MuiBox-root {
}

#Alliance-PC .title {
  width: 20%;
  height: 58px;
  line-height: 58px;
  margin: 16px;
}
#Alliance-PC .contentBody {
  width: 80%;
}

#Alliance-PC .imgDelBox {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: auto;
  background: #000;
  opacity: 0.6;
  display: none;
}

#Alliance-PC .imgDelBox .imgDelBoxInner {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

#Alliance-PC .imgDelBox .imgDelBox-icons {
  color: red;
  text-align: center;
  cursor: pointer;
  height: 90px;
  vertical-align: middle;
  display: table-cell;
}
/****** 제휴신청 PC ******/

/****** 예약 내역 ******/
/* 툴팁 타겟 */
#Reservation-Container {
  max-width:1400px
}

#Reservation-Container .cancelTooltip {
  position: relative;
  display: inline-block;
}

/* 툴팁 레이아웃 */
#Reservation-Container .tooltiptext {
  min-width: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10% 0;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  font-size: 13px;
  top: -80%;
  left: -160%;
}
/* 툴팁의 화살표 */
#Reservation-Container .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}
#Reservation-Container .tooltiptext:hover .tooltiptext {
  visibility: visible;
}

#Reservation-Container .Mui-focused {
  border: none;
}
#DatePicker .react-datepicker__input-container input {
  font-size: 1rem;
  text-align: center;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  border: 1px solid #ccc !important;
}

#DatePicker .react-datepicker__input-container input:hover {
  border: 1px solid #000 !important;
}

#DatePicker .react-datepicker__input-container input:focus {
  outline: none;
  border: 1px solid #4B93FF !important;
}

#DatePicker-withIcons .react-datepicker__input-container input {
  /* 양태욱 수정 */
  /* font-size: 1rem;  */
  cursor: pointer; /* 양태욱 추가 01.19 */
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  border-radius: 5px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  width: 150px;
}

#DatePicker-withIcons .react-datepicker__input-container input:hover {
  border: 1px solid #4B93FF;
}

/* 양태욱 추가 : DatePicker 아이콘에 마우스 hover시 노란 테두리필요 */
#DatePicker-withIcons .react-datepicker__input-container input.over {
  border: 1px solid #4B93FF;
}

#DatePicker-withIcons .react-datepicker__input-container input:focus {
  outline: none;
  border: 1px solid #4B93FF;
}
/* 양태욱 추가 (데이트 피커 공통)*/
/* 일반 DatePicker */
#DatePicker-withIcons .react-datepicker__day-name {
  font-weight: bold;
}
#DatePicker-withIcons .react-datepicker__day {
  font-weight: bold;
}
#DatePicker-withIcons .react-datepicker__day:focus {
  border: 0px solid white;
}
#DatePicker-withIcons .react-datepicker__day--keyboard-selected {
  background-color: white;
  color: black;
}
#DatePicker-withIcons .react-datepicker__day--in-range {
  color: black;
  background-color: #4B93FF;
}
#DatePicker-withIcons .react-datepicker__day--selected {
  color: black;
  background-color: #4B93FF;
}
#DatePicker-withIcons .react-datepicker__day--in-selecting-range {
  background-color: #ffa003;
  color: black;
}
#DatePicker-withIcons .react-datepicker__day--saturday {
  color: blue;
}
#DatePicker-withIcons .react-datepicker__day--sunday {
  color: red;
}
#DatePicker-withIcons .react-datepicker__day--disabled {
  color: #cccccc;
}

/* month-year DatePicker */
#DatePicker-withIcons .react-datepicker-year-header {
  font-weight: bold;
  font-size: 18px;
  padding: 3px;
}
#DatePicker-withIcons .react-datepicker__month-text {
  font-weight: bold;
  font-size: 14px;
  min-height: 30px;
  align-self: center;
  align-content: center;
  padding: 5px;
}
#DatePicker-withIcons .react-datepicker__month--in-range {
  color: black;
  background-color: #4B93FF;
}
#DatePicker-withIcons .react-datepicker__month--selected {
  color: black;
  background-color: #4B93FF;
}
#DatePicker-withIcons .react-datepicker__month-container {
  min-height: 180px;
}
/* ----------------------- */

/* 월 선택 스타일 : 양태욱*/
#MonthSelectorWrap .react-datepicker__month--selected {
  background-color: #ffffff;
}
#MonthSelectorWrap .react-datepicker__month-text:hover {
  background-color: #4B93FF;
}
#MonthSelectorWrap input[type="text"] {
  text-align: center;
  width: 120px;
}

#HourPicker .react-datepicker__input-container input {
  width: 80px;
  font-size: 1rem;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  border: 1px solid #ccc !important;
}

#HourPicker .MuiOutlinedInput-input {
  padding: 10px;
  /* padding: 10px !important; */
  /* background-color: #fff; */
}

#HourPicker .MuiInputBase-input.Mui-disabled {
  background-color: #eeeeee !important;
}

/* #HourPicker .MuiPopover-paper {
  background-color: #ddd;
} */
select option {
  color: #000000;
  font-weight: 700;
}
select option:disabled {
  color: #bbbbbb;
  font-weight: 400;
  background-color: #eeeeee;
}

#SelectList .MuiOutlinedInput-input {
  /*width: 75%;*/
  width:100%;
  text-indent: -9px;
  padding: 10px !important;
  background-color: #fff;
}

#SelectTinyBtn {
  /* display: inline-block; */
  display: flex;
  /* width: 20px; */
  /* position: relative;
  top: 2px; */
}

#SelectTinyBtn .MuiButton-root {
  color: #ffffff;
  background-color: #888;
  /* padding: 0 0.5em; */
  padding: 1px;
  margin: 1px;
  /* font-size: 0.7rem; */
  min-width: 20px;
  /* min-height: 10px; */
}

#SelectTinyBtn .MuiButton-root.Mui-disabled {
  background-color: #dddddd !important;
}

#SelectTinyBtn .MuiSvgIcon-root {
  font-size: 1rem;
}

#RemainGrid .MuiGrid-grid-xs-true {
  flex-basis: auto !important;
}

/* #TinyBtnSelect {
  display: inline-block;
  padding: 0 0.2em;
  position: relative;
  top: -6px;
} */

#TinyBtnSelect .MuiOutlinedInput-input {
  padding: 10px 5px !important;
}

#TinyBtnSelect .MuiOutlinedInput-input.Mui-disabled {
  background-color: #eeeeee !important;
}

/* 정산 내역 */
#settleDatatable .rdt_TableCell {
  text-align: left;
}

/* #TinyBtnSelect .MuiOutlinedInput-root {
  padding: 0 !important;
} */

#SelectCell .MuiOutlinedInput-input {
  padding: 10px !important;
}
/* 캘린더 툴팁 텍스트 */
[calendar-tooltip-text]:hover {
  position: relative;
}
[calendar-tooltip-text]:after {
  -webkit-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 7px 12px;
  position: absolute;
  width: 170px;
  z-index: 9999;
  line-height: 15px;
  opacity: 0;
  left: -9999px;
  top: 90%;
  content: attr(calendar-tooltip-text);
}
/* 위치 */
[calendar-tooltip-text]:hover:after {
  top: 100%;
  left: -30px;
  opacity: 1;
}
/* 안심번호 만료시 툴팁 */
[data-tooltip-text]:hover {
  position: relative;
}
[data-tooltip-text]:after {
  -webkit-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;

  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 7px 12px;
  position: absolute;
  min-width: 270px;
  max-width: 600px;
  word-wrap: break-word;

  z-index: 9999;

  opacity: 0;
  left: -9999px;
  top: 90%;

  content: attr(data-tooltip-text);
}
[data-tooltip-text]:hover:after {
  top: -150%;
  left: -30px;
  opacity: 1;
}

/* 예약내역,정산내역,부가세신고 툴팁 */
[summary-tooltip-text]:hover {
  position: relative;
}
[summary-tooltip-text]:after {
  -webkit-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;

  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 7px 12px;
  position: absolute;
  min-width: 270px;
  max-width: 600px;
  word-wrap: break-word;
  white-space: pre;
  z-index: 9999;

  opacity: 0;
  left: -9999px;
  top: 90%;

  content: attr(summary-tooltip-text);
}
[summary-tooltip-text]:hover:after {
  top: -60px;
  left: -120px;
  opacity: 1;
}

/* 오늘 선착순 쿠폰 현황 툴팁 */
[coupon-tooltip-text]:hover {
  position: relative;
}
[coupon-tooltip-text]:after {
  -webkit-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;

  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 7px 12px;
  position: absolute;
  min-width: 300px;
  max-width: 600px;
  word-wrap: break-word;
  white-space: pre;
  z-index: 9999;

  opacity: 0;
  left: -9999px;
  top: 90%;

  content: attr(coupon-tooltip-text);
}
[coupon-tooltip-text]:hover:after {
  top: -70px;
  left: -160px;
  opacity: 1;
}

/* 찜 고객관리 쿠폰 자동발행 헬프텍스트 */
[wish-tooltip-text]:hover {
  position: relative;
}
[wish-tooltip-text]:after {
  -webkit-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;

  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 7px 12px;
  position: absolute;
  min-width: 300px;
  max-width: 600px;
  word-wrap: break-word;
  white-space: pre;
  z-index: 9999;
  line-height: 15px;

  opacity: 0;
  left: -9999px;
  top: 90%;

  content: attr(wish-tooltip-text);
}
[wish-tooltip-text]:hover:after {
  top: -70px;
  left: -160px;
  opacity: 1;
}

/****** 메인 ******/

#Main-PC {
  margin: 0 auto;
  max-width: 1400px;
}

#Main-PC .BoxLeft {
  width: 65%;
}
#Main-PC .BoxRight {
  width: 35%;
}

#Main-PC .tabroot {
  flex-grow: 1;
}

/****** 메인 ******/

/****** 푸터 ******/
#Footer-PC a {
  text-decoration: none;
}

#Footer-PC a:link {
  color: #000;
}

#Footer-PC a:visited {
  color: #000;
}

#Footer-PC a:active {
  color: #000;
}

#Footer-PC a:hover {
  text-decoration: underline;
}

.footerWrap #Footer-PC {
  /*width: 1280px;*/
  width: 1400px;
}
@media (max-width: 1400px) {
  .footerWrap #Footer-PC {
    margin-left: calc((1400px - 100vw) / 2);
    padding-left: 1rem;
    width: 100vw;
  }
}
/****** 푸터 ******/
