body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 20200422 박태성 추가 */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-clear */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-reveal */
input {
  -webkit-appearance: none;
  appearance: none;
}
input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="password"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="password"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
